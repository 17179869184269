import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/Main';
import GlobalStore from './Context/GlobalStore';
import BootstrapBreakpointVisualiser from './components/BootstrapBreakpointVisualiser';
import MediaStore from './components/MediaStore';
import ShowPlaylistContent from './components/VIDEOS/ShowPlaylistContent';
import FacebookCounter from './components/DISPLAY/facebookCounter';

const App = () => {
  return (
    <GlobalStore>
      <Routes>
        <Route path='/Media' element={<MediaStore />} />
        <Route exact path='/ShowPlaylistContent' element={<ShowPlaylistContent />} />
        <Route path='/facebookCounter' element={<FacebookCounter />} />
        <Route path='/' element={<Main />} />
      </Routes>
    </GlobalStore>
  );
}

export default App;
