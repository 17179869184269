import { useEffect, useState } from 'react';
import CoolPlaylistSelectionButton from '../VIDEOS/CoolPlaylistSelectionButton';

const ytKey = process.env.REACT_APP_YOUTUBE_API_KEY;
const channelId = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;

const ShowPlaylistCollection = () => {
  const [playlistCollectionLocal, setPlaylistCollectionLocal] = useState(null);
  let PlaylistArray = [];
  useEffect(() => {
    let collection = getAllPlaylistsFromChannel(channelId, ytKey);
    let thumbs = getAllThumbnailImagesFromAllPlaylists(collection);
    ////console.log('playlists', collection);
    //console.log(thumbs);
  }, []);

  const getAllPlaylistsFromChannel = (channelId, ytKey) => {
    const url = `https://www.googleapis.com/youtube/v3/playlists?channelId=${channelId}&key=${ytKey}&part=snippet`;
    //console.log(url);
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    };

    fetch(url, options)
      .then((res) => {
        if (!res.ok) {
          throw Error('No channel could be found.');
        }
        return res.json();
      })
      .then((data) => {
        // //console.log('playlists:');
        // //console.log(data.items);
        return setPlaylistCollectionLocal(data.items);
      })
      .catch((err) => {
        //console.error('No channel could be found.');
      });
  };

  const getAllThumbnailImagesFromAllPlaylists = () => {
    // scan the channel data/playlists to get a collection of all thumbnail URLS
    // playlistCollectionLocal.map((playlist) => {
    //   return PlaylistArray.push(playlist.id);
    // });
    // //console.log('PlaylistArray');
    // //console.log(PlaylistArray);
    return PlaylistArray;
  };

  return (
    <div className='container mt-5'>
      <h1>Videos</h1>
      <ul>
        {playlistCollectionLocal && <>got playlists</> &&
          playlistCollectionLocal.map((playlist, index) => {
            return (
              <li id= {'PlaylistButton_' + index} key={index}>
               
                {/* <a className='btn btn-primary'>{playlist.id}</a> */}
                <CoolPlaylistSelectionButton
                  playlistId={playlist.id}
                  playlistTitle={playlist.snippet.title}
                  playListDescription={playlist.snippet.description}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ShowPlaylistCollection;
