import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import VideoCard from './VideoCard';
import Avatar from '../../images/avatar.png';
import { useNavigate } from 'react-router-dom';
const KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
const RESULTCOUNT = 20;

const ShowPlaylistContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [playlistCollectionLocal, setPlaylistCollectionLocal] = useState(null);
  ////console.log("from state", location.state);
  const playlistId = location.state.playlistId;
  const playlistTitle = location.state.playlistTitle;
  const [foundVideos, setFoundVideos] = useState(false);

  const handleBackToPlaylistsClick = () => {
    navigate('/');
  };

  useEffect(() => {
    //get the videos that live in the selected (id sent in location.state) playlist
    const url = `https://www.googleapis.com/youtube/v3/playlistItems?key=${KEY}&part=snippet&playlistId=${playlistId}&maxResults=${RESULTCOUNT}`;
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    };

    fetch(url, options)
      .then((res) => {
        //console.log(res);
        if (!res.ok) {
          throw Error('No playlist data could be found.');
        }
        return res.json();
      })
      .then((data) => {
        setPlaylistCollectionLocal(data);
        setFoundVideos(true);
      })
      .catch((err) => {
        //console.error('something went wrong', err.message);
      });
  }, []);

  return (
    <section className='container-fluid containerYTGallery'>
      <div className='row videoCardsRow mt-1'>
        <div className='col-12 GalleryHeader' id='gallery'>
          <div className='row'>
            <div className='col-2 col-md-1 col-lg-2'>
              <img
                src={Avatar}
                alt='logo'
                onClick={handleBackToPlaylistsClick}
                className='setCursor logoVideoGallery'
              />
            </div>
            <div className='col-8'>
              <h2 className='playlistTitle'>{playlistTitle}</h2>
            </div>
            <div className='col-2 align-right'>
              <button
                className='btn btn-danger'
                onClick={handleBackToPlaylistsClick}
              >
                X
              </button>
            </div>
          </div>
        </div>
        <div className='row p-2'>
          {foundVideos &&
            playlistCollectionLocal.items.map((vid, index) => {
              return (
                <VideoCard
                  key={index}
                  title={vid.snippet.title}
                  thumbnailUrl={vid.snippet.thumbnails.high.url}
                  YouTubeVideoId={vid.snippet.resourceId.videoId}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default ShowPlaylistContent;
