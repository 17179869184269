import MediaCard from './MediaCard';
import MediaData from './MediaData';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const MediaStore = () => {
  const mediaData = MediaData();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className='container-fluid containerBG pr'>
      <div className='row'>
        <div className='col-12 text-center' id='videoLinks'>
          <h1 className='headerText '>Media Collection</h1>
          <Link className='btn btn-outline-light backButton' to='/'>
                      Back
                    </Link>
          <p>
            Feel free to print these posters and add these pictures to your social media pages.
          </p>
          <div className='row'>
            {mediaData.map((md) => (
              <MediaCard
                key={md.id}
                title={md.title}
                description={md.description}
                downloadLink={md.downloadLink}
                thumbnailImage={md.thumbnailImage}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MediaStore;
