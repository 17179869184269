import { Link } from 'react-router-dom';

const CoolPlaylistSelectionButton = ({
  playlistId,
  playlistTitle,
  playListDescription,
}) => {
  return (
    <Link className='playlistbuttons' to='/ShowPlaylistContent' state={{ playlistId, playlistTitle }}>
      <div className='row playlistButton'>
        <div className="col-7 col-sm-7 col-md-8 col-lg-6">  <h1 className='playlistButtonHeader'>{playlistTitle}</h1></div>
        <div className="col-5 col-sm-5 col-md-4 col-lg-6"> <p className='playlistButtonDescription'>{playListDescription}</p></div>
      
       
      </div>
    </Link>
  );
};

export default CoolPlaylistSelectionButton;
