import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
const Footer = () => {
  return (
    <>
      <section className='container-fluid mt-5 mb-5' id='footer'>
        <div className='row'>
          <div id='left' className='col-12 col-lg-6'>
            <div className='row mt-3'>
              <h3 className='font-32'>Get in touch!</h3>
              <div className='footerSocials'>
                <div className='row mt-3'>
                  <div className='col-1 mt-1'>
                    <SocialIcon
                      url='https://www.facebook.com/theindiethieves'
                      style={{ height: 75, width: 75 }}
                      bgColor='transparent'
                    />
                  </div>
                  <div className='col-11 mt-1'>
                    <h3>
                      <a
                        href='https://www.facebook.com/theindiethieves'
                        target='_blank'
                        rel='noreferrer'
                      >
                        www.facebook.com/theindiethieves
                      </a>
                    </h3>
                  </div>
                  <div className='col-1 mt-1'>
                    <SocialIcon
                      href='mailto:contactus@theindiethieves.co.uk'
                      network='email'
                      style={{ height: 75, width: 75 }}
                      bgColor='transparent'
                    />
                  </div>
                  <div className='col-11'>
                    <h3>
                      <a href='mailto:contactus@theindiethieves.co.uk'>
                        contactus@theindiethieves.co.uk
                      </a>
                    </h3>
                  </div>

                  <div className='col-1 mt-1'>
                    <SocialIcon
                      url='https://www.youtube.com/@theindiethieves123'
                      style={{ height: 75, width: 75 }}
                      bgColor='transparent'
                    />
                  </div>
                  <div className='col-11'>
                    <h3>
                      <a href='https://www.youtube.com/@theindiethieves123'>
                        www.youtube.com/@theindiethieves123
                      </a>
                    </h3>
                  </div>
                  <div className='col-1 mt-1'>
                    <SocialIcon
                      url='https://www.instagram.com/indiethieves'
                      style={{ height: 75, width: 75 }}
                      bgColor='transparent'
                    />
                  </div>
                  <div className='col-11'>
                    <h3>
                      <a href='https://www.instagram.com/indiethieves'>
                        www.instagram.com/indiethieves
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id='right' className='col-12  col-lg-6'>
            <div className='row mt-3'>
              <div className='col-12 col-md-5'>
                <h3 className='font-32'>Promotional Media</h3>
                <ul>
                  <li>
                    <Link className='btn btn-outline-light' to='/Media'>
                      Get Posters
                    </Link>
                  </li>
                  {/* <li>
                    <Link className='btn btn-outline-light'to='/Media/'>
                      Get Logos
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='container-fluid mt-5' id='subfooter'>
        <p className='credits'>
          <a href='mailto:t.hodkinson@btinternet.com'>
            Website by Unicycle Software 2024
          </a>
        </p>
      </section>
    </>
  );
};

export default Footer;
