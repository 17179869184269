const MediaCard = ({
  id,
  title,
  description,
  type,
  downloadLink,
  thumbnail = '',
  thumbnailImage,
}) => {
  return (
    <div className='col-6 col-md-4 col-xl-3'>
      <article className='mediaCard'>
        <div className='card mt-2'>
          <div className='card-header mediaCardHeader'>
            {' '}
            <h3> {title}</h3>
          </div>
          <div className='card-body mediaCardBody'>
            <img src={thumbnailImage} alt='' />
            <p>{description}</p>
          </div>
          <div className='card-footer mediaCardFooter'>
            <a
              className='btn btn-dark footerDLButton'
              href={downloadLink}
              alt={description}
              target='_blank'
              rel='noreferrer'
              download
            >
              DOWNLOAD
            </a>
          </div>
        </div>
      </article>
    </div>
  );
};

export default MediaCard;
