import { useState } from 'react';
import tf from '../../images/BIOS/tf.jpg';
import tsl from '../../images/BIOS/tsl.jpg';
import tsr from '../../images/BIOS/tsr.jpg';
import jf from '../../images/BIOS/jf.jpg';
import jsl from '../../images/BIOS/jsl.jpg';
import jsr from '../../images/BIOS/jsr.jpg';
import thief1 from '../../images/BIOS/TobyMain.jpg';
import thief2 from '../../images/BIOS/JonMain.jpg';

const MobileBandBios = () => {
  const [showingToby, setShowingToby] = useState(true);
  const [slidePosition, setSlidePosition] = useState('slidePositionRight');
  const handleMouseClick = () => {
    setShowingToby(!showingToby);

    if (showingToby) setSlidePosition('slidePositionLeft');
    if (!showingToby) setSlidePosition('slidePositionRight');
    //console.log('click');
  };

  const handleMobileThiefButtons = () => {
    setShowingToby(!showingToby);
  }

  return (
    <div className='container'>
      <div className='row mb-3'>
        <div className='col-6'>
          <button className='btn btn-secondary w-100' onClick={handleMobileThiefButtons}>Thief 1</button>
        </div>
        <div className='col-6'>
          <button className='btn btn-secondary w-100' onClick={handleMobileThiefButtons}>Thief 2</button>
        </div>
      </div>
      {showingToby && <>
      <div className='row'>
        <div className='col-3 col-md-4'>
          <img
            src={thief1}
            className='MobileBioThief'
            alt='Toby from indie thieves'
          />
        </div>
        <div className='col-9 col-md-8 mobileTheifBioText'>
          <h1>Thief 1 - Lead guitar and backing vocals</h1>
          <p>
            Toby has been performing live for 25+ years. From flashy high energy
            playing to laid back jazz, he is comfortable playing in a wide range
            of situations.
          </p>
          <p>
            Years of performing up to 3 times a week has really honed his stage
            craft and terrible jokes.
          </p>
        </div>
      </div>
      <div className='row mobilethiefthumbs'>
        <div className='col-4'>
          <img src={tf} alt='' />
        </div>
        <div className='col-4'>
          <img src={tsl} alt='' />
        </div>
        <div className='col-4'>
          <img src={tsr} alt='' />
        </div>
        </div></>
      }
      {!showingToby && <>
      <div className='row'>
        <div className='col-3 col-md-4'>
          <img
            src={thief2}
            className='MobileBioThief'
            alt='Jon from indie thieves'
          />
        </div>
        <div className='col-9 col-md-8 mobileTheifBioText'>
          <h1>Thief 2 - Vocals and guitar</h1>
          <p>
                    Originally from Manchester, Jonny is an experienced
                    charismatic frontman with an incredible voice and a passion
                    for music.
                  </p>
                  <p>
                    A multi-instrumentalist, Jonny can be seen playing acoustic
                    guitars of various sizes plus a multitude of percussion
                    instruments. He is also a talented songwriter and prolific
                    capo user.
                  </p>
        </div>
      </div>
      <div className='row mobilethiefthumbs'>
        <div className='col-4'>
          <img src={jf} alt='' />
        </div>
        <div className='col-4'>
          <img src={jsl} alt='' />
        </div>
        <div className='col-4'>
          <img src={jsr} alt='' />
        </div>
        </div></>
      }
      {/* <div id='biosWrapper'>
        {showingToby && <div id='tobyMain' className=''></div>}

        {!showingToby && <div id='jonMain' className=''></div>}

        <div
          id='slider'
          className={`slide ${slidePosition}`}
          onClick={handleMouseClick}
        >
          {showingToby && (
            <div className='tobyText'>
              <div className='row'>
                <div className='col-8'>
                  <h1>Thief 1 - Lead guitar and backing vocals</h1>
                  <br />
                  <p>
                    Toby has been performing live for 25+ years. From flashy
                    high energy playing to laid back jazz, he is comfortable
                    playing in a wide range of situations.
                  </p>
                  <p>
                    Years of performing up to 3 times a week has really honed
                    his stage craft and terrible jokes.
                  </p>
                  <p className='clickforthief'>
                    <i>click for Thief 2</i>
                  </p>
                </div>
                <div className='col-4 slider-thumbs-wrapper'>
                  <div className='sliderThumb'>
                    <img src={tf} alt='' className='slider-thumb-image' />
                  </div>
                  <div className='sliderThumb'>
                    <img src={tsl} alt='' className='slider-thumb-image' />
                  </div>
                  <div className='sliderThumb'>
                    <img src={tsr} alt='' className='slider-thumb-image' />
                  </div>
                </div>
                <div className='jonPeeping'>
                  <img src={jonPeep} alt='' />
                </div>
              </div>
            </div>
          )}

          {!showingToby && (
            <div className='jonText'>
              <div className='row slider-thumbs-wrapper'>
                <div className='col-4'>
                  <div className='sliderThumb'>
                    <img src={jf} alt='' className='slider-thumb-image' />
                  </div>
                  <div className='sliderThumb'>
                    <img src={jsl} alt='' className='slider-thumb-image' />
                  </div>
                  <div className='sliderThumb'>
                    <img src={jsr} alt='' className='slider-thumb-image' />
                  </div>
                </div>
                <div className='col-8'>
                  {' '}
                  <h1>Thief 2 - Vocals and guitar</h1> <br />
                  <p>
                    Originally from Manchester, Jonny has spent all of his
                    gigging life so far on the South Coast so is very much
                    looking forward to being in front of Northern crowds.
                  </p>
                  <p>
                    Strictly acoustic guitar for Jonny following an unfortunate
                    incident involving electricty and a small fire (we dont like
                    to talk about it...)
                  </p>
                  <p className='clickforthief'>
                    <i>click for Thief 1</i>
                  </p>
                </div>
                <div className='tobyPeeping'>
                  <img src={tobyPeep} alt='' />
                </div>
              </div>
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default MobileBandBios;
