import { gapi } from 'gapi-script';
import { useEffect, useState, useContext } from 'react';
import Event from './Event';
import moment from 'moment';
import { GlobalContext } from '../Context/GlobalStore';

const Gigs = () => {
  const [gState, setGState] = useContext(GlobalContext);
  const [nextEvents, setNextEvents] = useState(null);
  const [allGigsLoaded, setAllGigsLoaded] = useState(false);
  let tempArray = [];
  const calendarID = process.env.REACT_APP_CALENDAR_ID;
  const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
  const today = moment();

  useEffect(() => {
    getEvents(calendarID, apiKey);
  }, []);

  useEffect(() => {
    if (allGigsLoaded === true) {
      //console.log('all gigs loaded');
    }
  }, [allGigsLoaded]);

  const getEvents = (calendarID, apiKey) => {
    function initialise() {
      gapi.client
        .init({
          apiKey: apiKey,
        })

        .then(function () {
          return gapi.client.request({
            path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events?orderBy=startTime&singleEvents=true`,
          });
        })

        .then(
          (response) => {
            let eventCollection = response.result.items;
            eventCollection.forEach((element) => {
              ////console.log(element)
              if (moment(element.start.dateTime).isAfter(today)) {
                if (
                  element.description !== undefined &&
                  element.location !== undefined &&
                  element.start.dateTime !== undefined
                ) {
                  tempArray.push({
                    Description: element.description,
                    Date: element.start.dateTime,
                    EventLocation: element.location,
                    Id: element.etag,
                  });
                }
              }
            });
            if (tempArray.length === 0) {
              return tempArray.push({
                Description: 'No upcoming events - ',
                Date: null,
                EventLocation: 'Please check back soon',
              });
            }
            setAllGigsLoaded(true);
            setNextEvents(tempArray);

            setGState({ ...gState, gigs: tempArray, gigsLoaded: true });

            return tempArray;
          },
          function (err) {
            //console.log(err);

            return [false, err];
          }
        );
    }
    gapi.load('client', initialise);
  };

  return (
    <section className='container'>
      <div className='row'>
        <div className='col-12 text-center'>
          <h1 className='WhoAreThey mt-10 '>Where to see us live</h1>
          <p>check back here for more...</p>
        </div>

        {!allGigsLoaded && (
          <p>
            <span className='spinner'></span>
          </p>
        )}
        {allGigsLoaded &&
          nextEvents.map((thisEvent, index) => {
            return (
              <Event
                key={index}
                description={thisEvent.Description}
                eventdate={thisEvent.Date}
                location={thisEvent.EventLocation}
              />
            );
          })}
      </div>
    </section>
  );
};

export default Gigs;
