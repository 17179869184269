import { createContext, useState } from 'react';

const initialGlobalState = {
  videoCount: 0,
  selectedVideoId: null,
  selectedVideoTitle: '',
  selectedPlaylistId: 1,
  showPlaylists: false,
  playlistCollection: null,
  nextGig: null,
  gigsLoaded: false,
  gigs: null
};

export const GlobalContext = createContext();

const GlobalStore = ({ children }) => {
  const [state, setState] = useState(initialGlobalState);

  return (
    <GlobalContext.Provider value={[state, setState]}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalStore;
