const MediaData = () => {
  return [
    {
      id: 1,
      title: 'A4 Poster 1',
      description:
        'Printable A4 size PDF poster with space to handwrite the date.',
      downloadLink: '/Downloadablemedia/Poster1A4.pdf',
      thumbnailImage: '/Downloadablemedia/poster1.png',
    },
    {
      id: 2,
      title: 'A4 Poster 2',
      description:
        'Printable A4 size PDF poster with space to handwrite the date.',
      downloadLink: '/Downloadablemedia/Poster2A4.pdf',
      thumbnailImage: '/Downloadablemedia/poster2.png',
    },
    {
      id: 3,
      title: 'A4 Poster 3',
      description:
        'Printable A4 size PDF poster with space to handwrite the date.',
      downloadLink: '/Downloadablemedia/Poster3A4.pdf',
      thumbnailImage: '/Downloadablemedia/poster3.png',
    },
    {
      id: 4,
      title: 'A4 Poster 4',
      description:
        'Printable A4 size PDF poster with space to handwrite the date.',
      downloadLink: '/Downloadablemedia/Poster4A4.pdf',
      thumbnailImage: '/Downloadablemedia/poster4.png',
    },
    {
      id: 5,
      title: 'A4 Poster 5',
      description:
        'Printable A4 size PDF poster with space to handwrite the date.',
      downloadLink: '/Downloadablemedia/Poster5A4.pdf',
      thumbnailImage: '/Downloadablemedia/poster5.png',
    },
    {
      id: 6,
      title: 'Small Logo',
      description: 'Indie Theives logo',
      downloadLink: '/DownloadableMedia/TheIndieThievesLogo.jpg',
      thumbnailImage: '/DownloadableMedia/TheIndieThievesLogo.jpg',
    },
    {
      id: 7,
      title: 'QR Tag',
      description: 'QR tag linking to Indie Thieves website',
      downloadLink: '/DownloadableMedia/qrcode.png',
      thumbnailImage: '/DownloadableMedia/qrcode.png',
    },
    {
      id: 8,
      title: 'Toby Mugshot',
      description: 'Image of Toby in mock police lineup',
      downloadLink: '/DownloadableMedia/mugshotToby.jpg',
      thumbnailImage: '/DownloadableMedia/mugshotToby.jpg',
    },
    {
      id: 9,
      title: 'Jon Mugshot',
      description: 'Image of Jon in mock police lineup',
      downloadLink: '/DownloadableMedia/mugshotJohn.jpg',
      thumbnailImage: '/DownloadableMedia/mugshotJohn.jpg',
    },
  ];
};

export default MediaData;
