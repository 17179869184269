import Moment from 'react-moment';
import { useState, useEffect } from 'react';

function NextGig({ events }) {
  // //console.log("NextGig prop",events)
  const [nextGigUpcoming, setNextGigUpcoming] = useState([]);
  const [next3Upcoming, setNext3Upcoming] = useState([]);

  useEffect(() => {
    //console.log('Next Gig');
    setNextGigUpcoming(GetNextEvents(events, 1));
    setNext3Upcoming(GetNextEvents(events, 3));
    ////console.log(nextGigUpcoming);
    ////console.log(next3Upcoming);
  }, []);

  const GetNextEvents = (eventList, numberToReturn) => {
    // //console.log(eventList, numberToReturn)
    // //console.log(eventList[0])
    if (!eventList) return [];

    let resultList = [];
    if (numberToReturn === 1 && eventList) {
      return eventList[0];
    } else {
      for (let x = 0; x <= numberToReturn - 1; x++) {
        resultList.push(eventList[x + 1]);
      }
    }
    return resultList;
  };

  return (
    <div className='container eventContainer' id='NextGigComponent'>
      <div className='mt-5 row event p-4'>
        <div className='col-12'>
          <div className='eventCardHeader'>
            <div className='row'>
              <div className='col-6 col-lg-9'>
                <p className='nextGigHeader pt-2'>Next Gigs...</p>
              </div>
              <div className='col-6 col-lg-3 small'>
                {/* show the first one at sizes lg and above, hide for smaller */}
                <a className='whiteClickableLink nextGigText d-none d-lg-block' href='#gigs' id="clickForFullList">
                  <u>Click here for full list</u>
                </a>
                 {/* show this one at small sizes hide for lg and above */}
                <a className='whiteClickableLink nextGigText d-lg-none pull-right' href='#gigs' id="clickForFullList">
                  <u>more...</u>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='col-8 col-lg-3'>
          <h2 className='blackText p-2 eventDate'>
            {
              <div className='mt-4'>
                <Moment format='ddd, DD MMM YY'>{nextGigUpcoming.Date}</Moment>
                {' - '}
                <Moment format='h:mm'>{nextGigUpcoming.Date}</Moment>
                {'pm'}
              </div>
            }
          </h2>
        </div>
        <div className='col-12 col-lg-5'>
          <h1 className='blackText eventDescription mt-4'>
            {nextGigUpcoming.Description}
          </h1>
        </div>
        <div className='col-12 col-lg-4 next3gigBg'>
          <ul>
            {next3Upcoming !== null &&
              next3Upcoming.map((event, index) => {
                return (
                  <li className='next3gigsLi' key={index}>
                    <Moment format='ddd, DD MMM YY'>{event.Date}</Moment>,{' '}
                    {event.Description}{' '}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NextGig;
