import React from 'react';

const InfoSlide = () => {
    
        return (
            <div>
                <h2>Please visit facebook and give us a follow!</h2>
            </div>
        );
    }


export default InfoSlide;