import BandBios from './ABOUT/BandBios';
import MobileBandBios from './ABOUT/MobileBandBios';
import Bumpf from './Bumf';
import Header from './Header';
import Footer from './Footer';
import { React, Fragment, useContext, useEffect } from 'react';
import { GlobalContext } from '../Context/GlobalStore';
import Gigs from './Gigs';
import BootstrapBreakpointVisualiser from './BootstrapBreakpointVisualiser';
import TagManager from 'react-gtm-module';
import ShowPlaylistCollection from './junk/ShowPlaylistCollection';
import NextGig from './NextGig';

const Main = () => {
  const [gState, setGState] = useContext(GlobalContext);

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GA4_MEASUREMENT_ID });
  }, []);

  // // useEffect(() => {
  // //   if (gState.gigsLoaded === true) {
  // //     //console.log('Main Found Gigs');
  // //   }
  // // }, [gState.gigsLoaded]);

  return (
    <>
      <BootstrapBreakpointVisualiser isVisible={false}>
        <Header />
        {gState.gigsLoaded === true && <NextGig events={gState.gigs} />}
        <Bumpf />
        <section id='bios'>
          <div className='d-none d-xxl-block'>
            <BandBios />
          </div>
          <div className='d-block d-xxl-none'>
            <MobileBandBios />
          </div>
        </section>
        <section id='videos'>
          <ShowPlaylistCollection />
        </section>
        <section id='gigs'>
          <Gigs />
        </section>
        <Footer />
      </BootstrapBreakpointVisualiser>
    </>
  );
};

export default Main;
