import { useState } from 'react';
import tf from '../../images/BIOS/tf.jpg';
import tsl from '../../images/BIOS/tsl.jpg';
import tsr from '../../images/BIOS/tsr.jpg';
import jf from '../../images/BIOS/jf.jpg';
import jsl from '../../images/BIOS/jsl.jpg';
import jsr from '../../images/BIOS/jsr.jpg';
import tobyPeep from '../../images/BIOS/tobyPeep.png';
import jonPeep from '../../images/BIOS/jonPeep.png';

const BandBios = () => {
  const [showingToby, setShowingToby] = useState(true);
  const [slidePosition, setSlidePosition] = useState('slidePositionRight');
  const handleMouseClick = () => {
    setShowingToby(!showingToby);

    if (showingToby) setSlidePosition('slidePositionLeft');
    if (!showingToby) setSlidePosition('slidePositionRight');
    //console.log('click');
  };

  return (
    <div className='container-fluid'>
      <div id='biosWrapper'>
        {showingToby && <div id='tobyMain' className=''></div>}

        {!showingToby && <div id='jonMain' className=''></div>}

        <div
          id='slider'
          className={`slide ${slidePosition}`}
          onClick={handleMouseClick}
        >
          {showingToby && (
            <div className='tobyText'>
              <div className='row'>
                <div className='col-8'>
                  <h1>Thief 1 - Lead guitar and backing vocals</h1>
                  <br />
                  <p>
                    Toby has been performing live for 25+ years. From flashy
                    high energy playing to laid back jazz, he is comfortable
                    playing in a wide range of situations.
                  </p>
                  <p>
                    Years of performing up to 3 times a week has really honed
                    his stage craft and terrible jokes.
                  </p>
                  <p className='clickforthief'>
                    <i>click for Thief 2</i>
                  </p>
                </div>
                <div className='col-4 slider-thumbs-wrapper'>
                  <div className='sliderThumb'>
                    <img src={tf} alt='' className='slider-thumb-image' />
                  </div>
                  <div className='sliderThumb'>
                    <img src={tsl} alt='' className='slider-thumb-image' />
                  </div>
                  <div className='sliderThumb'>
                    <img src={tsr} alt='' className='slider-thumb-image' />
                  </div>
                </div>
                <div className='jonPeeping'>
                  <img src={jonPeep} alt='' />
                </div>
              </div>
            </div>
          )}

          {!showingToby && (
            <div className='jonText'>
              <div className='row slider-thumbs-wrapper'>
                <div className='col-4'>
                  <div className='sliderThumb'>
                    <img src={jf} alt='' className='slider-thumb-image' />
                  </div>
                  <div className='sliderThumb'>
                    <img src={jsl} alt='' className='slider-thumb-image' />
                  </div>
                  <div className='sliderThumb'>
                    <img src={jsr} alt='' className='slider-thumb-image' />
                  </div>
                </div>
                <div className='col-8'>
                  {' '}
                  <h1>Thief 2 - Vocals and guitar</h1> <br />
                  <p>
                    Originally from Manchester, Jonny is an experienced
                    charismatic frontman with an incredible voice and a passion
                    for music.
                  </p>
                  <p>
                    A multi-instrumentalist, Jonny can be seen playing acoustic
                    guitars of various sizes plus a multitude of percussion
                    instruments. He is also a talented songwriter and prolific
                    capo user.
                  </p>
                  <p className='clickforthief'>
                    <i>click for Thief 1</i>
                  </p>
                </div>
                <div className='tobyPeeping'>
                  <img src={tobyPeep} alt='' />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BandBios;
