import React from 'react';
import Moment from 'react-moment';


function Event({ description, eventdate, location, ekey }) {
  

  return (
   
    <div className='container eventContainer' id={ekey}>
      <div className='mt-2 row event p-4'>
        <div className='col-12 col-lg-3'>
          <h2 className='blackText p-2 eventDate'>
            {eventdate !== null && (
              <>
              <Moment format='ddd, DD MMM YY'>{eventdate}</Moment>{' @'}
              <Moment format='hh:mm'>{eventdate}</Moment>{'pm'}
              </>
            )}
          </h2>
        </div>
        <div className='col-12 col-lg-8'>
          <h1 className='blackText eventDescription'>{description}</h1>
        </div>

        <div className='col-12 '>
          <p className='blackText p-2 eventLocation'>{location}</p>
        </div>
        <div className='col-12'></div>
      </div>
      </div>
     
  );
}

export default Event;
