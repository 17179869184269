import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';

const VideoCard = ({ title, thumbnailUrl, YouTubeVideoId, index }) => {
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);

  // useEffect(() => {
  //     if (YouTubeVideoId !== null) {
  //       setShow(true);
  //     }
  //   }, [YouTubeVideoId]);

  const showVideo = (e) => {
    setShow(true);
  };

  return (
    <>
      <div className='col-6 col-md-6 col-lg-4 col-xl-3' key={index}>
        <div className='card mt-2'>
          <div className='cardHeader'>
            <img
              src={thumbnailUrl}
              alt=''
              onClick={showVideo}
              id={YouTubeVideoId}
            />
          </div>
          <div className='cardBody'>
            <p>{title}</p>
          </div>
        </div>
      </div>

      {show === true && (
        <Modal show={show} onHide={handleClose} keyboard={false} size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>{title}</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              className='YTIframe'
              src={`https://www.youtube.com/embed/${YouTubeVideoId}?autoplay=1`}
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
              title='Embedded youtube'
              autoPlay='true'
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default VideoCard;
