const Bumpf = () => {
  return (
    <>
      {/* desktop only */}
      <div className='container-fluid offBlackBG mb-10 pt-5'>
        <section className='container-lg d-none d-lg-block'>
          <div className='row'>
            <div className='col-12 text-justify'>
              <h3 className='ls-5 roboto'>
                The Indie Thieves are a North-West based duo featuring two
                guitars and two vocals playing a wide variety of massive indie
                anthems spanning the last 3 decades.
              </h3>
              <h3 className='ls-5 roboto'>
                Hear bangers by Blur, Pulp and Oasis through to The Kooks,
                Kasabian and The Killers - plus a few surprises. All high energy
                fun tunes!!
              </h3>
              <br />
              <h3 className='ls-5 roboto'>
                Please contact the band through email or social media to book
                them for your pub, function or garden party!
              </h3><br />
              <h3 className='ls-5 roboto'>
                Take a look at the videos here and the{' '}
                <a
                  href='https://www.facebook.com/theindiethieves'
                  target='_blank'
                  rel='noreferrer'
                >
                  Facebook page
                </a>{' '}
                to get an idea of what we are all about.
              </h3>
              <br />
            </div>
          </div>
        </section>

        {/* mobile only  */}
        <section className='container d-block d-lg-none'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h2 className='font-21 ls-5 roboto m-2'>
                The Indie Thieves are a north-west based duo featuring two
                guitars and two vocals playing a wide variety of massive indie
                anthems spanning the last 3 decades.
              </h2>
              <h2 className='font-21 ls-5 roboto m-2'>
                Hear bangers by Blur, Pulp and Oasis through to The Kooks, Jamie
                T and The Strokes - plus a few surprises. All high energy fun
                tunes!!
              </h2>
              <h2 className='font-21 ls-5 roboto m-2'>
                Take a look at the videos here and the facebook page to get an
                idea of what they are all about.
              </h2>
              <h2 className='font-21 ls-5 roboto m-2'>
                Taking bookings now, they are happy to play pubs, functions and
                even house parties. Please contact the band through Email or
                Social Media.
              </h2>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Bumpf;
